import React from "react";
import ReactDOM from "react-dom";
import InvestWeb from "./pages";
import { ThemeProvider, CssBaseline, Typography } from "@mui/material";
import { theme } from "src/theme/Theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <InvestWeb />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
