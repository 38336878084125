import React from "react";
import { AppBar, Toolbar, Typography, Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavItems from "./NavItems";
//logoはsrc/images/logo.pngを参照

const Header = ({
  isMobile,
  toggleDrawer,
  scrollToTop,
  scrollToSection,
}: {
  isMobile: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  scrollToTop: () => void;
  scrollToSection: (section: string) => void;
}) => (
  <AppBar
    position="fixed"
    elevation={0}
    sx={{ backgroundColor: "#FFFFFF", borderBottom: "1px solid #E0E0E0" }}
  >
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <img
        src="/images/logo.png"
        alt="logo"
        style={{ width: 150, cursor: "pointer" }}
        onClick={scrollToTop}
      />
      {isMobile ? (
        <IconButton
          edge="start"
          color="default"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ color: "#14110f" }}
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavItems scrollToSection={scrollToSection} />
        </Box>
      )}
    </Toolbar>
  </AppBar>
);

export default Header;
