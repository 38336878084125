import { Box, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="footer"
      sx={{
        py: isMobile ? 2 : 3,
        backgroundColor: "#1d3a4d",
        color: "white",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography variant="body2" align="center" color={"#fff"}>
        &copy; Borderless inc. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
