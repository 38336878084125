import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Fab,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Member, companyProfile } from "src/utils/Profile";
import Header from "src/components/Header";
import Footer from "src/components/Footer";
import MapSection from "src/components/section/MapSection";
import SideDrawer from "../components/SideDrawer";
import { sections } from "src/components/NavItems";
import MemberSection from "src/components/section/MemberSection";
import AchievementSection from "src/components/section/AchievementSection";

const InvestWeb = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 64;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMember(null);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Headers */}
      <Header
        isMobile={isMobile}
        toggleDrawer={toggleDrawer}
        scrollToTop={scrollToTop}
        scrollToSection={scrollToSection}
      />
      <SideDrawer
        drawerOpen={drawerOpen}
        scrollToSection={scrollToSection}
        toggleDrawer={toggleDrawer}
      />
      {/* Main */}
      <Box
        sx={{
          width: "100%",
          height: isMobile ? "150px" : "200px",
          backgroundImage: "url(/images/HeaderImage.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Box sx={{ padding: "20px" }}>
        <Box
          sx={{
            maxWidth: "900px",
            margin: "0 auto",
            padding: isMobile ? "32px 16px" : "40px 24px",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            style={{ margin: "0 0 20px" }}
          >
            Mission
          </Typography>
          <Typography variant="subtitle1" align="center">
            『可能性』を今より
          </Typography>
        </Box>
        {sections.map((section) => {
          if (section === "Contact us") return null;

          return (
            <Box
              id={section.toLowerCase().replace(" ", "-")}
              sx={{
                maxWidth: "900px",
                margin: "0 auto",
                textAlign: "left",
                padding: isMobile ? "32px 16px" : "40px 24px",
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h5"
                align="center"
                style={{ margin: "20px 0" }}
              >
                {section}
              </Typography>
              {/* Servicesセクション */}
              {section === "Services" && (
                <Box sx={{ maxWidth: "900px", mx: "auto", px: 2 }}>
                  <Typography variant="body1">
                    M&Aアドバイザリー
                    <br />
                    企業のM&Aに関する戦略策定、デューデリジェンス、交渉支援、PMI等のサービス提供
                  </Typography>
                </Box>
              )}

              {/* Achievementセクション */}
              {section === "Team Achievement" && <AchievementSection />}

              {/* Company Profile セクション */}
              {section === "Company Profile" && (
                <Box sx={{ maxWidth: "900px", mx: "auto", px: 2 }}>
                  <Stack spacing={2}>
                    {Object.entries(companyProfile).map(
                      ([label, value], index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: "flex-start" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ flex: 1, fontWeight: "bold" }}
                          >
                            {label}
                          </Typography>
                          <Typography variant="body1" sx={{ flex: 2 }}>
                            {value}
                          </Typography>
                        </Stack>
                      )
                    )}
                  </Stack>
                </Box>
              )}

              {/* Member セクション */}
              {section === "Member" && <MemberSection />}
            </Box>
          );
        })}
      </Box>
      <MapSection />
      <Footer />

      {/* Others */}
      {isVisible && (
        <Fab
          color="default"
          size="small"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            bgcolor: "#EBEBEB",
            "&:hover": {
              bgcolor: "#D1D1D1",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobile}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>詳細情報</DialogTitle>
        <DialogContent>
          {selectedMember && (
            <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
              {selectedMember.detail}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default InvestWeb;
