import React from "react";
import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { sections, tabAction } from "src/components/NavItems";

type Props = {
  drawerOpen: boolean;
  scrollToSection: (section: string) => void;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const SideDrawer = ({ drawerOpen, scrollToSection, toggleDrawer }: Props) => {
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {sections.map((section) => (
            <ListItem
              key={section}
              onClick={() => tabAction(section)}
              style={{ cursor: "pointer" }}
            >
              <ListItemText primary={section} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
