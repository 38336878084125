import { Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  onClick?: () => void;
  isClicked: boolean;
};

const DetailButton = ({ onClick, isClicked }: Props) => {
  return (
    <Button
      variant="contained"
      endIcon={
        <ExpandMoreIcon
          sx={{
            transform: isClicked ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      }
      style={{
        borderRadius: "20px",
        padding: "2px 12px",
        backgroundColor: "#707070",
        color: "#FFFFFF",
      }}
      onClick={onClick || (() => {})}
      sx={{ "&:hover": { backgroundColor: "#8C8C8C" } }}
    >
      詳細
    </Button>
  );
};

export default DetailButton;
