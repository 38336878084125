import { Box } from "@mui/material";

const GOOGLE_MAPS_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapSection = () => {
  return (
    <Box
      sx={{
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {GOOGLE_MAPS_API_KEY && (
        <iframe
          width="90%"
          height="100%"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=日本橋水野ビル,東京都中央区日本橋室町1丁目11番12号`}
          allowFullScreen
        ></iframe>
      )}
    </Box>
  );
};

export default MapSection;
