import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Typography, Box, Stack, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { members } from "src/utils/Profile";
import DetailButton from "../DetailButton";

const formatDetailToTable = (detail: string) => {
  const rows = detail.split("\n").filter((row) => row.trim() !== "");

  const formattedRows = [];
  for (let i = 0; i < rows.length; i += 2) {
    const year = rows[i];
    const content = rows[i + 1] || "";
    formattedRows.push({
      year: year,
      content: content,
    });
  }

  return formattedRows;
};

// 各メンバーの詳細表示状態を管理するコンポーネント
const MemberSection = () => {
  const [visibleDetails, setVisibleDetails] = useState<boolean[]>(
    new Array(members.length).fill(false)
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDetailVisibility = (index: number) => {
    setVisibleDetails((prev) => {
      const newVisibleDetails = [...prev];
      newVisibleDetails[index] = !newVisibleDetails[index];
      return newVisibleDetails;
    });
  };

  const renderRuby = (name: string, ruby: string): JSX.Element => {
    const nameParts = name.split(" ");
    const rubyParts = ruby.split(" ");

    return (
      <>
        {nameParts
          .map((part, index) => (
            <ruby key={index}>
              {part}
              <rt
                style={{
                  fontWeight: "normal",
                  marginBottom: "4px",
                  fontSize: "0.6em",
                  color: "#111111",
                }}
              >
                {rubyParts[index]}
              </rt>
            </ruby>
          ))
          .reduce((prev, curr) => (
            <>
              {prev} {curr}
            </>
          ))}
      </>
    );
  };

  return (
    <Box sx={{ maxWidth: "900px", mx: "auto", px: 2 }}>
      <Stack spacing={6}>
        {members.map((member, index) => (
          <Stack
            key={index}
            direction={isMobile ? "column" : "row"}
            spacing={1}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {renderRuby(member.name, member.ruby)}
              </Typography>
              <Typography variant="body1">{member.label}</Typography>
              {member.image !== "" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "flex-start",
                    },
                  }}
                >
                  <img
                    src={`/images/${member.image}`}
                    alt={member.name}
                    style={{ width: "150px", margin: "10px" }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ flex: 2 }}>
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {member.content}
              </Typography>
              {((member.detail && member.detail !== "") ||
                (member.chronology && member.chronology !== "")) && (
                <Box sx={{ mt: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 1,
                    }}
                  >
                    <DetailButton
                      onClick={() => toggleDetailVisibility(index)}
                      isClicked={visibleDetails[index]}
                    />
                  </Box>
                </Box>
              )}
              {visibleDetails[index] && (
                <Box sx={{ mt: 1 }}>
                  {member.detail && (
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {member.detail}
                      </Typography>
                    </Box>
                  )}
                  {member.chronology && (
                    <Table>
                      <TableBody>
                        {formatDetailToTable(member.chronology).map(
                          (row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                borderBottom: "1px solid #e0e0e0", // 薄い線を追加
                              }}
                            >
                              <TableCell
                                align={"left"}
                                sx={{
                                  padding: "4px 0px",
                                  borderBottom: "none",
                                  verticalAlign: "top",
                                }}
                              >
                                <Typography variant="body2" fontSize={12}>
                                  {row.year}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  padding: "4px 8px", // パディングを最小限に設定
                                  borderBottom: "none",
                                  verticalAlign: "top",
                                }}
                              >
                                <Typography variant="body2" fontSize={13}>
                                  {row.content}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default MemberSection;
