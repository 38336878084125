import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "'Arial, Helvetica Neue', Arial, sans-serif",
    h5: {
      fontSize: "1.8rem",
      color: "#101769",
      fontFamily: "'Arial, Noto Sans JP', sans-serif",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "1.2rem",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "'Arial, Noto Sans JP', sans-serif",
    },
    body1: {
      fontSize: "1.1rem",
      color: "#000000",
      lineHeight: 1.6,
      fontFamily:
        "'Arial, Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', sans-serif",
    },
    body2: {
      fontSize: "1.0rem",
      color: "#020202",
      lineHeight: 2,
      fontFamily:
        "'Arial, Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', sans-serif",
    },
  },
  palette: {
    primary: {
      main: "#0000FF",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});
