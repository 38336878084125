import React from "react";
import { Button } from "@mui/material";

export const sections = [
  "Services",
  "Team Achievement",
  "Company Profile",
  "Member",
  "Contact us",
];

export const tabAction = (section: string) => {
  if (section === "Contact us") {
    window.open("https://forms.gle/WSxLhoCkTxKFsFT87", "_blank");
  } else {
    window.location.href = `#${
      section.toLowerCase().replace(" ", "-") || "services"
    }`;
  }
};

const NavItems = ({ scrollToSection }: any) => (
  <>
    {sections.map((section) => (
      <Button
        key={section}
        onClick={() => tabAction(section)}
        sx={{
          color: "#020202",
          textTransform: "none",
          margin: "0 8px",
        }}
      >
        {section}
      </Button>
    ))}
  </>
);

export default NavItems;
