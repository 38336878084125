import { useState } from "react";
import {
  Box,
  Stack,
  Card,
  CardContent,
  Typography,
  Collapse,
} from "@mui/material";
import { achievement } from "src/utils/Profile";
import DetailButton from "../DetailButton";

const AchievementSection = () => {
  const [visibleDetails, setVisibleDetails] = useState<boolean[]>(
    new Array(achievement.length).fill(false)
  );

  const toggleDetailVisibility = (index: number) => {
    setVisibleDetails((prev) => {
      const newVisibleDetails = [...prev];
      newVisibleDetails[index] = !newVisibleDetails[index];
      return newVisibleDetails;
    });
  };

  return (
    <Box sx={{ maxWidth: "900px", mx: "auto" }}>
      <Stack spacing={0}>
        {achievement.map((section, index) => (
          <Card
            key={index}
            sx={{
              boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.4)",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              borderRadius: 0,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => toggleDetailVisibility(index)}
          >
            <CardContent
              sx={{
                position: "relative",
                borderLeft: "4px solid #999",
                padding: "16px !important",
              }}
            >
              <Stack direction="column" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" fontWeight="bold">
                    {section.title}
                  </Typography>

                  {/* 詳細ボタン */}
                  <DetailButton isClicked={visibleDetails[index]} />
                </Stack>

                {/* 折り畳み部分 */}
                <Collapse
                  in={visibleDetails[index]}
                  timeout="auto"
                  unmountOnExit
                  style={{ padding: "0 16px 0 0" }}
                >
                  <Typography variant="body2" component="ul">
                    {section.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </Typography>
                </Collapse>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Box>
  );
};

export default AchievementSection;
